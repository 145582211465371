/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Box, MenuItem, TextField, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { getTestApiV2, setPageTitle, showToast } from 'modules/layout/layout.actions';
import { getAllPreferredExpenseCodes, getExpenseLines, getVoucherSummaries, postVoucherSummary } from 'modules/expenses/expenses.actions';
import { getAuthorizationSummaries } from 'modules/authorizations/store/actions/authorizations.actions';
import { getVendors } from 'modules/authorizations/store/actions/dataManagement.actions';
import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';
import { Button, Dialog, DialogActions, Typography } from '@mui/material';
import AdvancedSearch from 'modules/common/advancedSearch.component';
import Spinner from 'modules/common/spinner.component';
import {
  deleteVoucherRecurringPayable,
  getAllVoucherRecurringPayable,
  postVoucherRecurringPayable,
  updateVoucherRecurringPayable
} from './store/recurringPayables.action';
import auth from 'auth/auth';
import RecurringPayablesGridComponent from './recurringPayablesGrid.component';
import { chain, memoize } from 'lodash';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import {
  GET_CLIENT_PREFERRED_EXPENSE_CODES_FAILURE,
  GET_EXPENSE_LINES_FAILURE,
  GET_VOUCHER_SUMMARIES_FAILURE
} from 'modules/expenses/types';
import { GET_AUTHORIZATIONS_FAILURE } from 'modules/authorizations/store/types/authorizationTypes';
import { GET_VENDORS_FAILURE } from 'modules/authorizations/store/types/dataManagementTypes';
import { GET_RECURRING_PAYABLES_FAILURE, POST_RECURRING_PAYABLES_FAILURE } from './store/recurringPayables.types';
import { PayToChoiceOptions, RecurringPayableStatus, VoucherStatusOptions } from './recurringPayableConstants';

const styles = theme => ({
  headerContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 100
  }
});

export const recurringPayableStatusName = status => {
  switch (status) {
    case RecurringPayableStatus.Active:
      return 'Active';
    case RecurringPayableStatus.Pending:
      return 'Pending';
    case RecurringPayableStatus.Expired:
      return 'Expired';
    case RecurringPayableStatus.Cancelled:
      return 'Cancelled';
    default:
      return 'N/a';
  }
};

export const clientSearchInfo = clients => {
  return {
    searchingColumns: [
      { name: 'clientId', title: 'Client Id' },
      { name: 'clientName', title: 'Company Name' },
      { name: 'moveTrackClientId', title: 'MT Client Id' },
      { name: 'clientCode', title: 'Client Code' },
      { name: 'clientStatus', title: 'Status' },
      { name: 'serviceRep', title: 'Service Rep' }
    ],
    searchingColumnExtensions: [
      { columnName: 'clientId', width: 150 },
      { columnName: 'clientName', width: 250 },
      { name: 'moveTrackClientId', width: 175 },
      { name: 'clientCode', width: 175 },
      { columnName: 'clientStatus', width: 175 },
      { columnName: 'serviceRep', width: 175 }
    ],
    searchingRows: clients,
    idKey: 'clientId',
    nameKey: 'clientName'
  };
};

const getClientList = memoize(clients => {
  if (!clients) {
    return [];
  }
  const uniqueClients = chain(clients)
    .values()
    .uniqWith(clients, (a, b) => a.description === b.description)
    .value()
    .sort((a, b) => String(a.description).localeCompare(b.description))
    .filter(instance => instance.description);

  return uniqueClients;
});

const RecurringPayablesView = props => {
  let sortedExpenseAnalysts = ['JLBECKI', 'IHOLLIS', 'ASEITZ'];

  const sortedClients = getClientList(props.clients);

  const [showCreateRecurringPayable, setCreateShowRecurringPayable] = useState(false);

  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedClientId, setSelectedClientId] = useState(null);
  const [selectedClientName, setSelectedClientName] = useState(null);

  const [selectedVendorId, setSelectedVendorId] = useState(null);
  const [selectedVendorName, setSelectedVendorName] = useState(null);

  const [selectedAuthId, setSelectedAuthId] = useState(null);
  const [selectedAuthName, setSelectedAuthName] = useState(null);

  const [payToChoice, setPayToChoice] = useState(PayToChoiceOptions.Vendor);

  let unmappedClientsAdjusted = [];
  if (props.unmappedClients) {
    unmappedClientsAdjusted = props.unmappedClients.map(c => {
      let newCopy = { ...c };
      newCopy.clientName = c.description;
      newCopy.clientId = c.id;
      return newCopy;
    });
  }

  let clientAdvInfo = clientSearchInfo(unmappedClientsAdjusted);
  useEffect(() => {
    props.setPageTitle('Recurring Payables');

    const fetchData = async () => {
      setIsLoading(true);

      if (!props.recurringPayables || !props.recurringPayables.length > 0) {
        let { type } = await props.getAllVoucherRecurringPayable();
        if (type === GET_RECURRING_PAYABLES_FAILURE) {
          props.showToast('Failed to retrieve recurring payables, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        }
      }

      if (!props.expenseSummaries) {
        let { type } = await props.getVoucherSummaries();
        if (type === GET_VOUCHER_SUMMARIES_FAILURE) {
          props.showToast('Failed to retrieve vouchers, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        }
      }

      if (!props.expenseLines) {
        let { type } = await props.getExpenseLines();
        if (type === GET_EXPENSE_LINES_FAILURE) {
          props.showToast('Failed to retrieve expense lines, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        }
      }

      if (!props.vendors) {
        let { type } = await props.getVendors();
        if (type === GET_VENDORS_FAILURE) {
          props.showToast('Failed to retrieve vednors, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        }
      }

      if (!props.authorizationSummaries) {
        let { type } = await props.getAuthorizationSummaries();
        if (type === GET_AUTHORIZATIONS_FAILURE) {
          props.showToast('Failed to retrieve authorizations, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        }
      }

      if (!props.clientPreferredExpenseCodes) {
        let { type } = await props.getAllPreferredExpenseCodes();
        if (type === GET_CLIENT_PREFERRED_EXPENSE_CODES_FAILURE) {
          props.showToast('Failed to retrieve client preferred expense codes, please try again', {
            severity: TOAST_MESSAGE_SEVERITY_ERROR
          });
        }
      }
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const createRecurringPayable = async () => {
    setIsLoadingModal(true);

    const resp = await props.postVoucherRecurringPayable({
      recurringPayableId: 0,
      recurringPayableStatus: RecurringPayableStatus.Active,
      payToVendor: payToChoice === PayToChoiceOptions.Vendor,
      authorizationId: payToChoice === PayToChoiceOptions.EE ? selectedAuthId : null,
      vendorId: payToChoice === PayToChoiceOptions.Vendor ? selectedVendorId : null,
      amount: null,
      startDate: null,
      endDate: null,
      frequencyId: null,
      clientId: selectedClientId,
      clientName: selectedClientName,
      status: VoucherStatusOptions.Draft,
      vendorName: payToChoice === PayToChoiceOptions.Vendor ? selectedVendorName : null,
      authName: payToChoice === PayToChoiceOptions.EE ? selectedAuthName : null
    });

    if (!resp || resp.type === POST_RECURRING_PAYABLES_FAILURE) {
      showToast('Failed to create new recurring payable, please try again', {
        severity: 'error'
      });
    } else {
      props.history.push(`/recurringPayables/${resp.response.recurringPayableId}`);
    }
    setIsLoadingModal(false);
  };

  return (
    <>
      {isLoading ? (
        <FullscreenSpinner />
      ) : (
        <Box>
          <div className={props.classes.headerContainer}>
            <TextField
              select
              label="Clients"
              className={props.classes.textField}
              value={-1}
              onChange={e => {
                console.log(e.target.value);
              }}
              SelectProps={{
                autoWidth: true
              }}
              variant="outlined"
            >
              <MenuItem value={-1}>
                <em>All Clients</em>
              </MenuItem>
              {sortedClients.map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.description}
                </MenuItem>
              ))}
            </TextField>
            {auth.userHasRole('admin') && (
              <TextField
                select
                label="Expense Analyst"
                className={props.classes.textField}
                value={-1}
                onChange={e => {
                  console.log(e.target.value);
                }}
                SelectProps={{
                  autoWidth: true
                }}
                variant="outlined"
              >
                <MenuItem value={-1}>
                  <em>All Expense Analysts</em>
                </MenuItem>
                {sortedExpenseAnalysts.map(option => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </div>
          <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end', paddingRight: '10px' }}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                maxHeight: '40px',
                marginRight: '10px',
                marginTop: '8px',
                backgroundColor: '#5D9878',
                '&:hover': { backgroundColor: '#528569' }
              }}
              onClick={() => setCreateShowRecurringPayable(true)}
            >
              Create Recurring Payable
            </Button>
          </Box>
          {props.isLoading ? (
            <FullscreenSpinner />
          ) : (
            <RecurringPayablesGridComponent
              recurringPayables={props.recurringPayables}
              history={props.history}
              vendors={props.vendors}
              authorizations={props.authorizationSummaries}
              setIsLoading={setIsLoading}
            />
          )}
          <Dialog open={showCreateRecurringPayable} fullWidth={false} maxWidth={'lg'}>
            <Box sx={{ padding: '25px' }}>
              <Typography variant="h5">New Recurring Payable</Typography>
            </Box>
            <Box sx={{ padding: '25px', paddingTop: '0px' }}>
              <Typography variant="body2">Select from the base options below to get started.</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', padding: '10px 20px 10px 20px' }}>
              <Box sx={{ marginRight: '20px' }}>
                <AdvancedSearch
                  labelText={'Client'}
                  displayValueAccessorKey={'clientName'}
                  saveIdValueAccessorKey={'clientId'}
                  searchingCriteria={clientAdvInfo.searchingColumns}
                  searchingCriteriaExtensions={clientAdvInfo.searchingColumnExtensions}
                  searchingRows={unmappedClientsAdjusted}
                  id={selectedClientId}
                  value={selectedClientName}
                  setIdValue={(id, value) => {
                    setSelectedClientId(id);
                    setSelectedClientName(value);
                  }}
                  isReadOnly={false}
                />
                <Typography variant="caption" display="block" color="primary">
                  Required
                </Typography>
              </Box>
              <Box>
                <Button
                  color="secondary"
                  variant="contained"
                  sx={{
                    maxHeight: '40px',
                    marginLeft: '12px',
                    marginTop: '8px',
                    backgroundColor: payToChoice === PayToChoiceOptions.Vendor ? '#36939b' : '#ffffff',
                    '&:hover': { backgroundColor: payToChoice === PayToChoiceOptions.Vendor ? '#15585e' : '#faf8f2' },
                    color: payToChoice === PayToChoiceOptions.Vendor ? 'ffffff' : '#080600'
                  }}
                  onClick={() => setPayToChoice(PayToChoiceOptions.Vendor)}
                >
                  Pay to Vendor
                </Button>
                <Button
                  color="secondary"
                  variant="contained"
                  sx={{
                    maxHeight: '40px',
                    marginLeft: '12px',
                    marginTop: '8px',
                    backgroundColor: payToChoice === PayToChoiceOptions.EE ? '#36939b' : '#ffffff',
                    '&:hover': { backgroundColor: payToChoice === PayToChoiceOptions.EE ? '#15585e' : '#faf8f2' },
                    color: payToChoice === PayToChoiceOptions.EE ? 'ffffff' : '#080600'
                  }}
                  onClick={() => setPayToChoice(PayToChoiceOptions.EE)}
                >
                  Pay to EE
                </Button>
              </Box>

              {payToChoice === PayToChoiceOptions.Vendor && (
                <Box sx={{ marginLeft: '25px' }}>
                  <AdvancedSearch
                    labelText={'Vendor'}
                    displayValueAccessorKey={'companyName'}
                    saveIdValueAccessorKey={'vendorId'}
                    searchingCriteria={[
                      { name: 'vendorId', title: 'Vendor Id' },
                      { name: 'companyName', title: 'Company Name' },
                      { name: 'city', title: 'City' },
                      { name: 'state', title: 'State' },
                      { name: 'phone', title: 'Company Phone' },
                      { name: 'email', title: 'Company Email' }
                    ]}
                    searchingCriteriaExtensions={[
                      { columnName: 'vendorId', width: 150 },
                      { columnName: 'companyName', width: 250 },
                      { name: 'city', width: 175 },
                      { name: 'state', width: 175 },
                      { columnName: 'phone', width: 175 },
                      { columnName: 'email', width: 175 }
                    ]}
                    searchingRows={props.vendors}
                    id={selectedVendorId}
                    value={selectedVendorName}
                    setIdValue={(id, value) => {
                      setSelectedVendorId(id);
                      setSelectedVendorName(value);
                    }}
                    isReadOnly={false}
                  />
                  <Typography variant="caption" display="block" color="primary">
                    Required
                  </Typography>
                </Box>
              )}

              {payToChoice === PayToChoiceOptions.EE && (
                <Box sx={{ marginLeft: '25px' }}>
                  <AdvancedSearch
                    labelText={'Authorization'}
                    displayValueAccessorKey={'transfereeName'}
                    saveIdValueAccessorKey={'authorizationId'}
                    searchingCriteria={[
                      { name: 'authorizationId', title: 'Auth Id' },
                      { name: 'transfereeName', title: 'Full Name' },
                      { name: 'departure', title: 'Departure Location' },
                      { name: 'destination', title: 'Destination Location' },
                      { name: 'clientName', title: 'Client' }
                    ]}
                    searchingCriteriaExtensions={[
                      { columnName: 'authorizationId', width: 150 },
                      { columnName: 'transfereeName', width: 250 },
                      { name: 'departure', width: 175 },
                      { name: 'destination', width: 175 },
                      { columnName: 'clientName', width: 175 }
                    ]}
                    searchingRows={
                      selectedClientName
                        ? props.authorizationSummaries.filter(a => a.clientName === selectedClientName)
                        : props.authorizationSummaries
                    }
                    id={selectedAuthId}
                    value={selectedAuthName}
                    setIdValue={(id, value) => {
                      if (id && value) {
                        let relevantAuthInfo = props.authorizationSummaries.find(a => a.authorizationId === id);
                        let relevantClientInfo = unmappedClientsAdjusted.find(c => c.clientName === relevantAuthInfo.clientName);

                        setSelectedAuthId(id);
                        setSelectedAuthName(value);

                        setSelectedClientId(relevantClientInfo.clientId);
                        setSelectedClientName(relevantClientInfo.clientName);
                      } else {
                        setSelectedAuthId(id);
                        setSelectedAuthName(value);
                      }
                    }}
                    isReadOnly={false}
                  />
                  <Typography variant="caption" display="block" color="primary">
                    Required
                  </Typography>
                </Box>
              )}
            </Box>
            <DialogActions sx={{ p: '1.25rem' }}>
              {isLoadingModal && <Spinner />}
              {!isLoadingModal && (
                <>
                  <Button
                    onClick={() => this.setState({ showCreateVoucher: false })}
                    sx={{ color: '#41B7C0', '&:hover': { color: '#3d959c' } }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={createRecurringPayable}
                    color="primary"
                    sx={{ backgroundColor: '#41B7C0', '&:hover': { backgroundColor: '#3d959c' } }}
                    type="submit"
                    variant="contained"
                    disabled={
                      selectedClientId == null ||
                      (payToChoice === PayToChoiceOptions.Vendor && (selectedVendorId == null || selectedVendorId === '')) ||
                      (payToChoice === PayToChoiceOptions.EE && (selectedAuthId == null || selectedAuthId === ''))
                    }
                  >
                    Create Recurring Payable
                  </Button>
                </>
              )}
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </>
  );
};

const mapStateToProps = state => {
  const {
    authorizations: { authorizationSummaries, vendors },
    expenses: { voucherSummaries, expenseLines, isLoading, clientPreferredExpenseCodes },
    clients: { clients, unmappedClients },
    recurringPayables: { recurringPayables }
  } = state;
  return {
    voucherSummaries,
    expenseLines,
    clients,
    unmappedClients,
    isLoading,
    vendors,
    authorizationSummaries,
    clientPreferredExpenseCodes,
    recurringPayables
  };
};

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, {
    setPageTitle,
    showToast,
    getTestApiV2,
    getVoucherSummaries,
    getExpenseLines,
    getAuthorizationSummaries,
    getVendors,
    postVoucherSummary,
    getAllPreferredExpenseCodes,
    getAllVoucherRecurringPayable,
    postVoucherRecurringPayable,
    updateVoucherRecurringPayable,
    deleteVoucherRecurringPayable
  })
)(RecurringPayablesView);
