import { RelationshipOptions } from '../../Employee/FormFields/formFieldConstants';
import { assignmentTypeOptions, costCapOptions, noticeToVacateOptions, vendorOptions } from '../TempLivingConstants';

export const calculateDaysRequested = (estimatedMoveIn, estimatedMoveOut) => {
  if (!estimatedMoveIn || !estimatedMoveOut) return 0;

  const startDate = new Date(estimatedMoveIn);
  const endDate = new Date(estimatedMoveOut);

  if (isNaN(startDate) || isNaN(endDate)) return 0;

  const diffInMs = endDate - startDate;
  return Math.max(Math.ceil(diffInMs / (1000 * 60 * 60 * 24)), 0);
};

const createFormField = (header, accessorKey, type, value, tab, required, showBelow = false, disabled = false, options = null) => {
  const field = {
    header,
    accessorKey,
    type,
    value,
    tab,
    required,
    showBelow,
    disabled
  };

  if (type === 'dropdown' && options) {
    field.options = options;
  }

  return field;
};

export const createNewFieldsForm = () => {
  const fields = [
    createFormField(
      'Assignment Type',
      'assignmentType',
      'dropdown',
      assignmentTypeOptions[0],
      1,
      true,
      false,
      false,
      assignmentTypeOptions
    ),
    createFormField('Temp Living City', 'moveToCity', 'text', null, 1, true)
  ];

  return fields;
};

export const createInitializationFormFields = (formData, pets, familyMembers) => {
  let familyMembersSafty = familyMembers && Array.isArray(familyMembers) ? familyMembers : [];
  let petsSafty = pets && Array.isArray(pets) ? pets : [];

  const daysRequested = calculateDaysRequested(formData?.estimatedMoveIn, formData?.estimatedMoveOut);
  const isHighlighted = daysRequested > (formData?.daysAllowed ?? 0);

  const fields = [
    formData?.initializationDate &&
      createFormField('Initiated Date', 'initializationDate', 'date', formData.initializationDate, 1, false, false, false, []),
    createFormField(
      'Assignment Type',
      'assignmentType',
      'dropdown',
      formData?.assignmentType ?? assignmentTypeOptions[0],
      1,
      true,
      false,
      false,
      assignmentTypeOptions
    ),
    createFormField('Temp Living City', 'moveToCity', 'text', formData?.moveToCity, 1, true),
    createFormField('Temp Living State', 'moveToState', 'text', formData?.moveToState, 1, false),

    createFormField('Estimated Move In', 'estimatedMoveIn', 'date', formData?.estimatedMoveIn, 1, false),
    createFormField('Estimated Move Out', 'estimatedMoveOut', 'date', formData?.estimatedMoveOut, 1, false),
    {
      header: 'Days Requested',
      accessorKey: 'daysRequested',
      type: 'int',
      required: false,
      value: daysRequested,
      hideInModal: true,
      tab: 1,
      isHighlighted: isHighlighted
    },
    createFormField('Days Allowed', 'daysAllowed', 'number', formData?.daysAllowed, 1, false),
    createFormField('Cost Cap', 'costCap', 'number', formData?.costCap, 1, false),
    createFormField('Daily Cost Cap', 'dailyCostCap', 'number', formData?.dailyCostCap, 1, false),
    createFormField('Cost Cap Options', 'costCapOption', 'dropdown', formData?.costCapOption, 1, false, false, false, costCapOptions),
    createFormField('Booking Source', 'vendor', 'dropdown', formData?.vendor, 1, false, false, false, vendorOptions),
    {
      header: 'Unit Type',
      accessorKey: 'unitType',
      type: 'dropdown',
      options: ['Hotel', 'Apartment', 'Corporate Housing'],
      required: false,
      value: formData?.unitType,
      tab: 1
    },
    {
      header: 'Family',
      accessorKey: 'family',
      type: 'multi-select-v2',
      options: familyMembersSafty
        .filter(f => f.relationship !== RelationshipOptions[0])
        .map(f => ({
          id: f.familyMemberId,
          name: `${f.firstName} ${f.lastName}`
        })),
      required: false,
      value:
        formData?.familyMemberIds && Array.isArray(formData.familyMemberIds)
          ? familyMembersSafty
              .filter(f => formData.familyMemberIds.includes(f.familyMemberId))
              .map(f => ({
                id: f.familyMemberId,
                name: `${f.firstName} ${f.lastName}`
              }))
          : [],
      tab: 1
    },
    {
      header: 'Pets',
      accessorKey: 'pets',
      type: 'multi-select-v2',
      options: petsSafty.map(f => ({
        id: f.familyPetId,
        name: f.petName
      })),
      required: false,
      value:
        formData?.petIds && Array.isArray(formData.petIds)
          ? petsSafty
              .filter(f => formData.petIds.includes(f.familyPetId))
              .map(f => ({
                id: f.familyPetId,
                name: f.petName
              }))
          : [],
      tab: 1
    },
    createFormField('Number of Bedrooms', 'numberOfBedrooms', 'number', formData?.numberOfBedrooms, 1, false),
    createFormField('Special Needs', 'specialNeeds', 'notes', formData?.specialNeeds, 1, false, false)
  ].filter(Boolean); // Remove undefined values for conditional fields

  return fields;
};

export const createTempLivingPropertyFormFields = formData => {
  const fields = [
    createFormField(
      'Confirm Booking Source',
      'vendorName',
      'dropdown',
      formData?.vendorName ?? formData?.vendor,
      1,
      false,
      false,
      false,
      vendorOptions
    ),
    createFormField('Complex Name', 'complexName', 'text', formData?.complexName, 1, false),
    createFormField('Street Address', 'streetAddress', 'text', formData?.streetAddress, 1, false),
    createFormField('Unit Number', 'unitNumber', 'text', formData?.unitNumber, 1, false),
    createFormField('City', 'city', 'text', formData?.city, 1, false),
    createFormField('State', 'state', 'text', formData?.state, 1, false),
    createFormField('Zip Code', 'zipCode', 'text', formData?.zipCode, 1, false),
    createFormField('Country', 'country', 'text', formData?.country, 1, false),
    createFormField('Daily Rate', 'dailyRate', 'number', formData?.dailyRate, 1, false)
  ];

  return fields;
};

export const createMovedOutAndMovedInFormFields = formData => {
  const daysUsed = calculateDaysRequested(formData?.movedInDate, formData?.movedOutDate);
  const fields = [
    createFormField('Actual Move In', 'movedInDate', 'date', formData?.movedInDate, 1, false),
    createFormField(
      'Notice to Vacate',
      'noticeToVacate',
      'dropdown',
      formData?.noticeToVacate,
      1,
      false,
      false,
      false,
      noticeToVacateOptions
    ),
    createFormField('Notice Due Date', 'noticeDueDate', 'date', formData?.noticeDueDate, 1, false),
    createFormField('Notice Given', 'noticeGiven', 'date', formData?.noticeGiven, 1, false, false),
    createFormField('Actual Move Out', 'movedOutDate', 'date', formData?.movedOutDate, 1, false, false),
    {
      header: 'Days Used',
      accessorKey: 'daysUsed',
      type: 'number',
      required: false,
      value: daysUsed,
      hideInModal: true,
      tab: 1
    },
    createFormField('Exception Days', 'exceptionDays', 'number', formData?.exceptionDays, 1, false, false)
  ];
  return fields;
};

export const createFormFieldsForTempLiving = (formData, authPets, currentStatus) => {
  return [
    ...createInitializationFormFields(formData, authPets),
    ...createTempLivingPropertyFormFields(formData),
    ...createMovedOutAndMovedInFormFields(formData, currentStatus)
  ];
};
