/* eslint-disable react-hooks/exhaustive-deps */

import { Box, ThemeProvider, createTheme } from '@mui/material';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';
import React, { useEffect, useState } from 'react';
import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { getSurveyQuestions, setSurveyLoadingComplete, getSurveyQuestionResponses, getSurveyQuestionVariations, setQuestionId } from '../surveys.actions';
import { GET_SURVEY_QUESTIONS_FAILURE, GET_SURVEY_QUESTION_RESPONSES_FAILURE, GET_SURVEY_QUESTION_VARIATIONS_FAILURE } from '../surveys.types';
import SurveyQuestionResponseDetails from './surveyQuestionResponseDetails.component';
import { getVendors } from 'modules/authorizations/store/actions/dataManagement.actions';
import { getAuthorizationSummaries } from 'modules/authorizations/store/actions/authorizations.actions';
import { GET_VENDORS_FAILURE } from 'modules/authorizations/store/types/dataManagementTypes';
import { GET_AUTHORIZATIONS_FAILURE } from 'modules/authorizations/store/types/authorizationTypes';

const styles = theme => ({
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px'
  }
});

const SurveyQuestionResponseDetailsView = props => {
  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING
    }
  });

  const [variations, setVariations] = useState(null);

  const responseDetails = props.surveyQuestionResponses ? props.surveyQuestionResponses.find(sq => sq.responseId === parseInt(props.match.params.responseId)) : null;
  const questionDetails = props.surveyQuestions && responseDetails ? props.surveyQuestions.find(sq => sq.surveyQuestionId === parseInt(responseDetails.surveyQuestionId)) : null;
  const authDetails = props.authorizationSummaries && responseDetails ? props.authorizationSummaries.find(auth => auth.authorizationId === responseDetails.authorizationId) : null;
  const vendor = props.vendors && responseDetails ? props.vendors.find(v => v.vendorId === parseInt(responseDetails.linkedVendorId)) : null;
  const variation = variations && responseDetails ? variations.find(v => v.surveyQuestionVariationId === parseInt(responseDetails.surveyQuestionVariationId)) : null;

  console.log(responseDetails);
  const [isLoading, setIsLoading] = useState(false);

   useEffect(() => {
     async function fetchData () {
      setIsLoading(true);

       if (!props.surveyQuestions) {
         let { type } = await props.getSurveyQuestions();
         if (type === GET_SURVEY_QUESTIONS_FAILURE) {
             this.props.showToast('Failed to retrieve survey questions, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
         }
       }

       if (!props.surveyQuestionResponses) {
        let { responseType } = await props.getSurveyQuestionResponses();
        if (responseType === GET_SURVEY_QUESTION_RESPONSES_FAILURE) {
            this.props.showToast('Failed to retrieve survey question responses, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        }

        if (!props.vendors) {
          let { vendorType } = await this.props.getVendors();
          if (vendorType.type === GET_VENDORS_FAILURE) {
            this.props.showToast('Failed to retrieve vednors, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          }
        }

        if (!props.authorizationSummaries) {
          let { authType } = await this.props.getAuthorizationSummaries();
          if (authType.type === GET_AUTHORIZATIONS_FAILURE) {
            this.props.showToast('Failed to retrieve authorizations, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          }
        }
      
      }

       setIsLoading(false);
     }

     async function fetchVariations (questionId) {
      if (!variations ) {
        let { type, response } = await props.getSurveyQuestionVariations(questionId);
        if (type === GET_SURVEY_QUESTION_VARIATIONS_FAILURE) {
            this.props.showToast('Failed to retrieve survey question variations, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        }
        else {
          setVariations(response);
        }
      }
     }

     fetchData();

     if(responseDetails && responseDetails.surveyQuestionId)
     {
      fetchVariations(responseDetails.surveyQuestionId);
     }

   }, [responseDetails, variations]);

  return (
    <ThemeProvider theme={theme}>
      <Box>
        {!isLoading && questionDetails && <Box sx={{ padding: '30px' }}><SurveyQuestionResponseDetails surveyQuestion={questionDetails} responseDetails={responseDetails} authorization={authDetails} vendor={vendor} variation={variation} /></Box>}
        {isLoading && <FullscreenSpinner />}
      </Box>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
    const {
        authorizations: { authorizationSummaries, vendors },
        surveys: { surveyQuestions, surveyQuestionResponses, isLoading },
    } = state;
    return { authorizationSummaries, vendors, surveyQuestions, surveyQuestionResponses, isLoading };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getSurveyQuestions,
    setSurveyLoadingComplete,
    getSurveyQuestionResponses,
    getVendors,
    getAuthorizationSummaries,
    setQuestionId,
    getSurveyQuestionVariations,
  })
)(SurveyQuestionResponseDetailsView);
