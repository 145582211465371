export const GET_EXPENSE_SUMMARIES_REQUEST = 'GET_EXPENSE_SUMMARIES_REQUEST';
export const GET_EXPENSE_SUMMARIES_SUCCESS = 'GET_EXPENSE_SUMMARIES_SUCCESS';
export const GET_EXPENSE_SUMMARIES_FAILURE = 'GET_EXPENSE_SUMMARIES_FAILURE';

export const POST_EXPENSE_SUMMARY_REQUEST = 'POST_EXPENSE_SUMMARY_REQUEST';
export const POST_EXPENSE_SUMMARY_SUCCESS = 'POST_EXPENSE_SUMMARY_SUCCESS';
export const POST_EXPENSE_SUMMARY_FAILURE = 'POST_EXPENSE_SUMMARY_FAILURE';

export const UPDATE_EXPENSE_SUMMARY_REQUEST = 'UPDATE_EXPENSE_SUMMARY_REQUEST';
export const UPDATE_EXPENSE_SUMMARY_SUCCESS = 'UPDATE_EXPENSE_SUMMARY_SUCCESS';
export const UPDATE_EXPENSE_SUMMARY_FAILURE = 'UPDATE_EXPENSE_SUMMARY_FAILURE';

export const DELETE_EXPENSE_SUMMARY_REQUEST = 'DELETE_EXPENSE_SUMMARY_REQUEST';
export const DELETE_EXPENSE_SUMMARY_SUCCESS = 'DELETE_EXPENSE_SUMMARY_SUCCESS';
export const DELETE_EXPENSE_SUMMARY_FAILURE = 'DELETE_EXPENSE_SUMMARY_FAILURE';

export const GET_EXPENSE_SUMMARIES_BY_AUTH_REQUEST = 'GET_EXPENSE_SUMMARIES_BY_AUTH_REQUEST';
export const GET_EXPENSE_SUMMARIES_BY_AUTH_SUCCESS = 'GET_EXPENSE_SUMMARIES_BY_AUTH_SUCCESS';
export const GET_EXPENSE_SUMMARIES_BY_AUTH_FAILURE = 'GET_EXPENSE_SUMMARIES_BY_AUTH_FAILURE';

export const GET_EXPENSE_SUMMARY_BY_ID_REQUEST = 'GET_EXPENSE_SUMMARY_BY_ID_REQUEST';
export const GET_EXPENSE_SUMMARY_BY_ID_SUCCESS = 'GET_EXPENSE_SUMMARY_BY_ID_SUCCESS';
export const GET_EXPENSE_SUMMARY_BY_ID_FAILURE = 'GET_EXPENSE_SUMMARY_BY_ID_FAILURE';

export const GET_EXPENSE_LINE_REQUEST = 'GET_EXPENSE_LINE_REQUEST';
export const GET_EXPENSE_LINE_SUCCESS = 'GET_EXPENSE_LINE_SUCCESS';
export const GET_EXPENSE_LINE_FAILURE = 'GET_EXPENSE_LINE_FAILURE';

export const POST_EXPENSE_LINE_REQUEST = 'POST_EXPENSE_LINE_REQUEST';
export const POST_EXPENSE_LINE_SUCCESS = 'POST_EXPENSE_LINE_SUCCESS';
export const POST_EXPENSE_LINE_FAILURE = 'POST_EXPENSE_LINE_FAILURE';

export const UPDATE_EXPENSE_LINE_REQUEST = 'UPDATE_EXPENSE_LINE_REQUEST';
export const UPDATE_EXPENSE_LINE_SUCCESS = 'UPDATE_EXPENSE_LINE_SUCCESS';
export const UPDATE_EXPENSE_LINE_FAILURE = 'UPDATE_EXPENSE_LINE_FAILURE';

export const DELETE_EXPENSE_LINE_REQUEST = 'DELETE_EXPENSE_LINE_REQUEST';
export const DELETE_EXPENSE_LINE_SUCCESS = 'DELETE_EXPENSE_LINE_SUCCESS';
export const DELETE_EXPENSE_LINE_FAILURE = 'DELETE_EXPENSE_LINE_FAILURE';

export const GET_EXPENSE_LINES_BY_AUTH_REQUEST = 'GET_EXPENSE_LINES_BY_AUTH_REQUEST';
export const GET_EXPENSE_LINES_BY_AUTH_SUCCESS = 'GET_EXPENSE_LINES_BY_AUTH_SUCCESS';
export const GET_EXPENSE_LINES_BY_AUTH_FAILURE = 'GET_EXPENSE_LINES_BY_AUTH_FAILURE';

export const GET_EXPENSE_LINES_REQUEST = 'GET_EXPENSE_LINES_REQUEST';
export const GET_EXPENSE_LINES_SUCCESS = 'GET_EXPENSE_LINES_SUCCESS';
export const GET_EXPENSE_LINES_FAILURE = 'GET_EXPENSE_LINES_FAILURE';

export const GET_EXPENSE_LINE_BY_SUMMARY_ID_REQUEST = 'GET_EXPENSE_LINE_BY_SUMMARY_ID_REQUEST';
export const GET_EXPENSE_LINE_BY_SUMMARY_ID_SUCCESS = 'GET_EXPENSE_LINE_BY_SUMMARY_ID_SUCCESS';
export const GET_EXPENSE_LINE_BY_SUMMARY_ID_FAILURE = 'GET_EXPENSE_LINE_BY_SUMMARY_ID_FAILURE';

export const POST_EXPENSE_LINE_MULTIPLE_REQUEST = 'POST_EXPENSE_LINE_MULTIPLE_REQUEST';
export const POST_EXPENSE_LINE_MULTIPLE_SUCCESS = 'POST_EXPENSE_LINE_MULTIPLE_SUCCESS';
export const POST_EXPENSE_LINE_MULTIPLE_FAILURE = 'POST_EXPENSE_LINE_MULTIPLE_FAILURE';

export const GET_EXPENSE_LINE_CUSTOM_TAXES_REQUEST = 'GET_EXPENSE_LINE_CUSTOM_TAXES_REQUEST';
export const GET_EXPENSE_LINE_CUSTOM_TAXES_SUCCESS = 'GET_EXPENSE_LINE_CUSTOM_TAXES_SUCCESS';
export const GET_EXPENSE_LINE_CUSTOM_TAXES_FAILURE = 'GET_EXPENSE_LINE_CUSTOM_TAXES_FAILURE';

export const UPDATE_EXPENSE_LINE_CUSTOM_TAXES_REQUEST = 'UPDATE_EXPENSE_LINE_CUSTOM_TAXES_REQUEST';
export const UPDATE_EXPENSE_LINE_CUSTOM_TAXES_SUCCESS = 'UPDATE_EXPENSE_LINE_CUSTOM_TAXES_SUCCESS';
export const UPDATE_EXPENSE_LINE_CUSTOM_TAXES_FAILURE = 'UPDATE_EXPENSE_LINE_CUSTOM_TAXES_FAILURE';

export const POST_EXPENSE_LINE_CUSTOM_TAXES_REQUEST = 'POST_EXPENSE_LINE_CUSTOM_TAXES_REQUEST';
export const POST_EXPENSE_LINE_CUSTOM_TAXES_SUCCESS = 'POST_EXPENSE_LINE_CUSTOM_TAXES_SUCCESS';
export const POST_EXPENSE_LINE_CUSTOM_TAXES_FAILURE = 'POST_EXPENSE_LINE_CUSTOM_TAXES_FAILURE';

export const DELETE_EXPENSE_LINE_CUSTOM_TAXES_REQUEST = 'DELETE_EXPENSE_LINE_CUSTOM_TAXES_REQUEST';
export const DELETE_EXPENSE_LINE_CUSTOM_TAXES_SUCCESS = 'DELETE_EXPENSE_LINE_CUSTOM_TAXES_SUCCESS';
export const DELETE_EXPENSE_LINE_CUSTOM_TAXES_FAILURE = 'DELETE_EXPENSE_LINE_CUSTOM_TAXES_FAILURE';

export const GET_VOUCHER_SUMMARIES_REQUEST = 'GET_VOUCHER_SUMMARIES_REQUEST';
export const GET_VOUCHER_SUMMARIES_SUCCESS = 'GET_VOUCHER_SUMMARIES_SUCCESS';
export const GET_VOUCHER_SUMMARIES_FAILURE = 'GET_VOUCHER_SUMMARIES_FAILURE';

export const GET_VOUCHER_SUMMARY_BY_ID_REQUEST = 'GET_VOUCHER_SUMMARY_BY_ID_REQUEST';
export const GET_VOUCHER_SUMMARY_BY_ID_SUCCESS = 'GET_VOUCHER_SUMMARY_BY_ID_SUCCESS';
export const GET_VOUCHER_SUMMARY_BY_ID_FAILURE = 'GET_VOUCHER_SUMMARY_BY_ID_FAILURE';

export const GET_VOUCHER_SUMMARIES_BY_AUTH_REQUEST = 'GET_VOUCHER_SUMMARIES_BY_AUTH_REQUEST';
export const GET_VOUCHER_SUMMARIES_BY_AUTH_SUCCESS = 'GET_VOUCHER_SUMMARIES_BY_AUTH_SUCCESS';
export const GET_VOUCHER_SUMMARIES_BY_AUTH_FAILURE = 'GET_VOUCHER_SUMMARIES_BY_AUTH_FAILURE';

export const POST_VOUCHER_SUMMARY_REQUEST = 'POST_VOUCHER_SUMMARY_REQUEST';
export const POST_VOUCHER_SUMMARY_SUCCESS = 'POST_VOUCHER_SUMMARY_SUCCESS';
export const POST_VOUCHER_SUMMARY_FAILURE = 'POST_VOUCHER_SUMMARY_FAILURE';

export const UPDATE_VOUCHER_SUMMARY_REQUEST = 'UPDATE_VOUCHER_SUMMARY_REQUEST';
export const UPDATE_VOUCHER_SUMMARY_SUCCESS = 'UPDATE_VOUCHER_SUMMARY_SUCCESS';
export const UPDATE_VOUCHER_SUMMARY_FAILURE = 'UPDATE_VOUCHER_SUMMARY_FAILURE';

export const DELETE_VOUCHER_SUMMARY_REQUEST = 'DELETE_VOUCHER_SUMMARY_REQUEST';
export const DELETE_VOUCHER_SUMMARY_SUCCESS = 'DELETE_VOUCHER_SUMMARY_SUCCESS';
export const DELETE_VOUCHER_SUMMARY_FAILURE = 'DELETE_VOUCHER_SUMMARY_FAILURE';

export const GET_EXPENSE_LINE_BY_LINE_ID_REQUEST = 'GET_EXPENSE_LINE_BY_LINE_ID_REQUEST';
export const GET_EXPENSE_LINE_BY_LINE_ID_SUCCESS = 'GET_EXPENSE_LINE_BY_LINE_ID_SUCCESS';
export const GET_EXPENSE_LINE_BY_LINE_ID_FAILURE = 'GET_EXPENSE_LINE_BY_LINE_ID_FAILURE';

export const GET_CLIENT_PREFERRED_EXPENSE_CODES_REQUEST = 'GET_CLIENT_PREFERRED_EXPENSE_CODES_REQUEST';
export const GET_CLIENT_PREFERRED_EXPENSE_CODES_SUCCESS = 'GET_CLIENT_PREFERRED_EXPENSE_CODES_SUCCESS';
export const GET_CLIENT_PREFERRED_EXPENSE_CODES_FAILURE = 'GET_CLIENT_PREFERRED_EXPENSE_CODES_FAILURE';