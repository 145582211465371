export const GET_SURVEY_QUESTIONS_REQUEST = 'GET_SURVEY_QUESTIONS_REQUEST';
export const GET_SURVEY_QUESTIONS_SUCCESS = 'GET_SURVEY_QUESTIONS_SUCCESS';
export const GET_SURVEY_QUESTIONS_FAILURE = 'GET_SURVEY_QUESTIONS_FAILURE';

export const GET_SURVEY_QUESTION_BY_ID_REQUEST = 'GET_SURVEY_QUESTION_BY_ID_REQUEST';
export const GET_SURVEY_QUESTION_BY_ID_SUCCESS = 'GET_SURVEY_QUESTION_BY_ID_SUCCESS';
export const GET_SURVEY_QUESTION_BY_ID_FAILURE = 'GET_SURVEY_QUESTION_BY_ID_FAILURE';

export const UPDATE_SURVEY_QUESTION_REQUEST = 'UPDATE_SURVEY_QUESTION_REQUEST';
export const UPDATE_SURVEY_QUESTION_SUCCESS = 'UPDATE_SURVEY_QUESTION_SUCCESS';
export const UPDATE_SURVEY_QUESTION_FAILURE = 'UPDATE_SURVEY_QUESTION_FAILURE';

export const GET_SURVEY_QUESTION_TRIGGERS_REQUEST = 'GET_SURVEY_QUESTION_TRIGGERS_REQUEST';
export const GET_SURVEY_QUESTION_TRIGGERS_SUCCESS = 'GET_SURVEY_QUESTION_TRIGGERS_SUCCESS';
export const GET_SURVEY_QUESTION_TRIGGERS_FAILURE = 'GET_SURVEY_QUESTION_TRIGGERS_FAILURE';

export const POST_SURVEY_QUESTION_TRIGGER_REQUEST = 'POST_SURVEY_QUESTION_TRIGGER_REQUEST';
export const POST_SURVEY_QUESTION_TRIGGER_SUCCESS = 'POST_SURVEY_QUESTION_TRIGGER_SUCCESS';
export const POST_SURVEY_QUESTION_TRIGGER_FAILURE = 'POST_SURVEY_QUESTION_TRIGGER_FAILURE';

export const UPDATE_SURVEY_QUESTION_TRIGGER_REQUEST = 'UPDATE_SURVEY_QUESTION_TRIGGER_REQUEST';
export const UPDATE_SURVEY_QUESTION_TRIGGER_SUCCESS = 'UPDATE_SURVEY_QUESTION_TRIGGER_SUCCESS';
export const UPDATE_SURVEY_QUESTION_TRIGGER_FAILURE = 'UPDATE_SURVEY_QUESTION_TRIGGER_FAILURE';

export const DELETE_SURVEY_QUESTION_TRIGGER_REQUEST = 'DELETE_SURVEY_QUESTION_TRIGGER_REQUEST';
export const DELETE_SURVEY_QUESTION_TRIGGER_SUCCESS = 'DELETE_SURVEY_QUESTION_TRIGGER_SUCCESS';
export const DELETE_SURVEY_QUESTION_TRIGGER_FAILURE = 'DELETE_SURVEY_QUESTION_TRIGGER_FAILURE';

export const GET_SURVEY_QUESTION_VARIATIONS_REQUEST = 'GET_SURVEY_QUESTION_VARIATIONS_REQUEST';
export const GET_SURVEY_QUESTION_VARIATIONS_SUCCESS = 'GET_SURVEY_QUESTION_VARIATIONS_SUCCESS';
export const GET_SURVEY_QUESTION_VARIATIONS_FAILURE = 'GET_SURVEY_QUESTION_VARIATIONS_FAILURE';

export const POST_SURVEY_QUESTION_VARIATION_REQUEST = 'POST_SURVEY_QUESTION_VARIATION_REQUEST';
export const POST_SURVEY_QUESTION_VARIATION_SUCCESS = 'POST_SURVEY_QUESTION_VARIATION_SUCCESS';
export const POST_SURVEY_QUESTION_VARIATION_FAILURE = 'POST_SURVEY_QUESTION_VARIATION_FAILURE';

export const UPDATE_SURVEY_QUESTION_VARIATION_REQUEST = 'UPDATE_SURVEY_QUESTION_VARIATION_REQUEST';
export const UPDATE_SURVEY_QUESTION_VARIATION_SUCCESS = 'UPDATE_SURVEY_QUESTION_VARIATION_SUCCESS';
export const UPDATE_SURVEY_QUESTION_VARIATION_FAILURE = 'UPDATE_SURVEY_QUESTION_VARIATION_FAILURE';

export const DELETE_SURVEY_QUESTION_VARIATION_REQUEST = 'DELETE_SURVEY_QUESTION_VARIATION_REQUEST';
export const DELETE_SURVEY_QUESTION_VARIATION_SUCCESS = 'DELETE_SURVEY_QUESTION_VARIATION_SUCCESS';
export const DELETE_SURVEY_QUESTION_VARIATION_FAILURE = 'DELETE_SURVEY_QUESTION_VARIATION_FAILURE';

export const GET_SURVEY_QUESTION_RESPONSES_REQUEST = 'GET_SURVEY_QUESTION_RESPONSES_REQUEST';
export const GET_SURVEY_QUESTION_RESPONSES_SUCCESS = 'GET_SURVEY_QUESTION_RESPONSES_SUCCESS';
export const GET_SURVEY_QUESTION_RESPONSES_FAILURE = 'GET_SURVEY_QUESTION_RESPONSES_FAILURE';

export const GET_SURVEY_QUESTION_RESPONSES_BY_AUTH_REQUEST = 'GET_SURVEY_QUESTION_RESPONSES_BY_AUTH_REQUEST';
export const GET_SURVEY_QUESTION_RESPONSES_BY_AUTH_SUCCESS = 'GET_SURVEY_QUESTION_RESPONSES_BY_AUTH_SUCCESS';
export const GET_SURVEY_QUESTION_RESPONSES_BY_AUTH_FAILURE = 'GET_SURVEY_QUESTION_RESPONSES_BY_AUTH_FAILURE';

export const SET_QUESTION_ID = 'SET_QUESTION_ID';
export const SET_SURVEY_LOADING_COMPLETE = 'SET_SURVEY_LOADING_COMPLETE';