import {
  Box, Grid,
} from '@mui/material';

import AddressCard from './addressCard.component';
// import ClientPoliciesCard from './clientPoliciesCard.component';
import EmployeeCard from './Employee/employeeCard.component';
import React, {useState} from 'react';
import ServicesCard from './servicesCard.component';
import StatusStepper from './statusStepper.component';
import TeamCard from './teamCard.component';
import HeaderCard from './headerCard.component';
import FinanceAndTaxCard from './financeAndTaxCard.component';
import NotesAndCommunicationCard from './notesAndCommunicationCard.component';
import CommunicationCard from './communicationCard.component';
import SurveyResponseDialog from './authorizationSurveyResultsDialog.component';

const AuthorizationDetails = (props) => {
  const details = props.details;
  const status = details?.authDetails?.summary  ? details?.authDetails?.summary.status  : 'Init';

  console.log(details);

  const [renderSurveyModal, setRenderSurveyModal] = useState(false);

  const sendSurveyClicked = async () => {
    setRenderSurveyModal(true);
  }

  return (
    <Box sx={{ padding: '20px' }}>
      { status && details?.authDetails?.dateSummary && details?.authDetails?.userSettings && <StatusStepper status={status} dateSummary={details?.authDetails?.dateSummary} vip={details?.authDetails?.userSettings.vip} webAccess={details?.authDetails?.userSettings.webAccess} surveySent={details?.authDetails?.summary.surveySent} renderSurveyModal={sendSurveyClicked} resetSurveyApiCall={props.resetSurveyApiCall}/> }
      <Box sx={{ paddingBottom: '16px' }}>
        <HeaderCard clientInfo={props.clientInfo} details={details?.authDetails} id={props.id} />
      </Box>
      <Box sx={{ paddingBottom: '16px', fullWidth: true, }}>
        <ServicesCard details={details} fileStatus={status} webAccess={details?.authDetails?.userSettings.webAccess} authServices={details?.authDetails?.authServices} destinationServices={details?.authDestinationService?.destinationServices} candidateServices={details?.authCanidateTrips?.candidateServices} internServices={details?.authCanidateTrips?.internServices} id={props.id} moveType={details?.authDetails?.summary.moveType} />
      </Box>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
        <Grid item xs={12} md={8} rowSpacing={2}>
          <NotesAndCommunicationCard id={props.id} authServices={details?.authDetails?.authServices} commentsAndNotes={details?.commentsAndNotes} />
          <Box sx={{ paddingTop: '16px' }}>
            <EmployeeCard details={details?.authDetails} clientInfo={props.clientInfo} employee={details?.authDetails?.employee} family={details?.authDetails?.family} id={props.id} vendors={props.vendors} familyPets={details?.authDetails?.familyPets} />
          </Box>
          <Box sx={{ paddingTop: '16px' }}>
            <AddressCard departureAddress={details?.authDetails?.departureAddress} destinationAddress={details?.authDetails?.destinationAddress} id={props.id} addresses={details?.authDetails?.addresses} />
          </Box>
        </Grid>
        <Grid item xs={12} md={4} rowSpacing={2}>
          <CommunicationCard clientInfo={props.clientInfo} communication={details?.authDetails?.communication} id={props.id} />
          <Box sx={{ paddingTop: '16px' }}>
            <FinanceAndTaxCard details={details} clientInfo={props.clientInfo} clientTaxPolicies={details?.authTaxAssistance?.clientTaxPolicies} id={props.id} />
          </Box>
          <Box sx={{ paddingTop: '16px' }}>
            <TeamCard team={details?.authDetails?.team} id={props.id} />
          </Box>
        </Grid>
      </Grid>
      { renderSurveyModal &&
      <>
        <SurveyResponseDialog
          open={true}
          onCancel={() => setRenderSurveyModal(false)}
          authorization={details?.authDetails?.summary}
          onSubmit={() => setRenderSurveyModal(false)}
          surveyQuestionResponses={details?.surveyQuestionResponses}
        />
      </>
      }
    </Box>
  );
};

export default AuthorizationDetails;